import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function Workshops({ data: { strapi: { workshopsPage } }, location }) {

	return (
		<>
			<SEO title="Workshops" description={ workshopsPage.seoDescription } pathname={ location.pathname } />

			<div className="content-page">
				{ workshopsPage && workshopsPage.content &&
					<DynamicZone components={ workshopsPage.content } />
				}
			</div>
		</>
	)
}

export const query = graphql`
	query workshopsPageQuery {
		strapi {
			workshopsPage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentCommonStatement {
						id
						statement
						url
						urlText
					}
					... on StrapiQuery_ComponentCommonVideo {
						videoNumber
						videoTitle
					}
					... on StrapiQuery_ComponentCommonImage {
						id
						displayBorder
						title
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonTestimonial {
						id
						introduction
						testimonial {
							slug
							thumbnail {
								url
								imageFile {
									childImageSharp {
										fluid(maxWidth: 400) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonContactForm {
						id
						message
					}
					... on StrapiQuery_ComponentCommonDivider {
						id
						display
					}
					... on StrapiQuery_ComponentCommonQuote {
						id
						author
						quote
					}
					... on StrapiQuery_ComponentWorkshopsWorkshopsList {
						id
						heading
					}
				}
			}
		}
	}
`